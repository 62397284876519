import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faVideo, faChartSimple, faUserCheck, faWandMagicSparkles, faExclamationTriangle, faCube, faHandPointUp } from '@fortawesome/free-solid-svg-icons';

const HeroSection = styled.section`
  background: var(--gradient);
  padding: 150px 0 100px;
  text-align: center;
  color: white;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
`;

const HeroTitle = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
`;

const HeroDescription = styled.p`
  font-size: 20px;
  max-width: 800px;
  margin: 0 auto 40px;
  opacity: 0.9;
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  padding: 80px 0;
`;

const FeatureCard = styled.div`
  background-color: var(--card-bg);
  border-radius: 20px;
  padding: 40px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(108, 99, 255, 0.2);
  }
`;

const FeatureIcon = styled.div`
  font-size: 48px;
  color: var(--accent-color);
  margin-bottom: 20px;
`;

const FeatureTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 15px;
  color: var(--primary-color);
`;

const FeatureDescription = styled.p`
  font-size: 16px;
  color: var(--secondary-color);
`;

const CTASection = styled.section`
  background-color: var(--secondary-color);
  color: white;
  text-align: center;
  padding: 80px 0;
  clip-path: polygon(0 15%, 100% 0, 100% 85%, 0 100%);
`;

const CTATitle = styled.h2`
  font-size: 36px;
`;

const CTAButton = styled(Link)`
  display: inline-block;
  background-color: var(--accent-color);
  color: white;
  padding: 15px 30px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 25px rgba(0,0,0,0.2);
    background-color: var(--primary-color);
  }
`;

const Features = () => {
  return (
    <>
      <HeroSection>
        <div className="container">
          <HeroTitle>Netrah Features</HeroTitle>
          <HeroDescription>Discover the power of our advanced Computer Vision AI technology</HeroDescription>
        </div>
      </HeroSection>

      <main className="container">
        <FeaturesGrid>
          <FeatureCard>
            <FeatureIcon className="floating-icon">
              <FontAwesomeIcon icon={faEye} />
            </FeatureIcon>
            <FeatureTitle>Advanced Object Recognition</FeatureTitle>
            <FeatureDescription>Identify and classify objects with superhuman accuracy in real-time, powered by our state-of-the-art deep learning models.</FeatureDescription>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon className="floating-icon">
              <FontAwesomeIcon icon={faVideo} />
            </FeatureIcon>
            <FeatureTitle>Real-time Video Analysis</FeatureTitle>
            <FeatureDescription>Process and analyze live video streams for instant insights and decision-making, enabling applications from security to sports analytics.</FeatureDescription>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon className="floating-icon">
              <FontAwesomeIcon icon={faChartSimple} />
            </FeatureIcon>
            <FeatureTitle>Visual Data Analytics</FeatureTitle>
            <FeatureDescription>Extract meaningful insights from complex visual data, transforming images and video into actionable business intelligence.</FeatureDescription>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon className="floating-icon">
              <FontAwesomeIcon icon={faUserCheck} />
            </FeatureIcon>
            <FeatureTitle>Facial Recognition</FeatureTitle>
            <FeatureDescription>Identify and verify individuals with high accuracy while maintaining privacy standards, perfect for security and personalized experiences.</FeatureDescription>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon className="floating-icon">
              <FontAwesomeIcon icon={faWandMagicSparkles} />
            </FeatureIcon>
            <FeatureTitle>Image Enhancement</FeatureTitle>
            <FeatureDescription>Automatically improve image quality and enhance details using AI algorithms, bringing clarity to even the most challenging visual data.</FeatureDescription>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon className="floating-icon">
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </FeatureIcon>
            <FeatureTitle>Anomaly Detection</FeatureTitle>
            <FeatureDescription>Spot unusual patterns or objects in images and video streams for enhanced security and quality control in various industries.</FeatureDescription>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon className="floating-icon">
              <FontAwesomeIcon icon={faCube} />
            </FeatureIcon>
            <FeatureTitle>3D Scene Reconstruction</FeatureTitle>
            <FeatureDescription>Transform 2D images into detailed 3D models, opening up possibilities in architecture, gaming, and virtual reality.</FeatureDescription>
          </FeatureCard>
          <FeatureCard>
            <FeatureIcon className="floating-icon">
              <FontAwesomeIcon icon={faHandPointUp} />
            </FeatureIcon>
            <FeatureTitle>Gesture Recognition</FeatureTitle>
            <FeatureDescription>Interpret human gestures and body language in real-time, enabling advanced human-computer interactions and accessibility features.</FeatureDescription>
          </FeatureCard>
        </FeaturesGrid>
      </main>

      <CTASection>
        <div className="container">
          <CTATitle>Ready to experience the power of Netrah?</CTATitle>
          <CTAButton to="/demo">Request a Demo</CTAButton>
        </div>
      </CTASection>
    </>
  );
};

export default Features;
