import React from 'react';
import styled from 'styled-components';

const ContactHeader = styled.section`
  background: var(--gradient);
  color: white;
  text-align: center;
  padding: 150px 0 100px;
  margin-bottom: 80px;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
`;

const ContactTitle = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
`;

const ContactDescription = styled.p`
  font-size: 20px;
  max-width: 600px;
  margin: 0 auto;
  opacity: 0.9;
`;

const ContactContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
`;

const ContactForm = styled.form`
  flex: 1;
  background-color: var(--card-bg);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
`;

const FormTitle = styled.h2`
  color: var(--primary-color);
  margin-bottom: 30px;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  color: var(--secondary-color);
`;

const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
`;

const FormSelect = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
`;

const FormTextarea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  height: 150px;
`;

const SubmitButton = styled.button`
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 12px 30px;
  font-size: 18px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--accent-color);
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(108, 99, 255, 0.3);
  }
`;

const ContactInfo = styled.div`
  flex: 0 0 300px;
  margin-left: 40px;
`;

const InfoCard = styled.div`
  background-color: var(--card-bg);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  margin-bottom: 30px;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const InfoCardTitle = styled.h3`
  color: var(--primary-color);
  margin-bottom: 15px;
`;

const InfoCardText = styled.p`
  color: var(--secondary-color);
`;

const InfoCardLink = styled.a`
  color: var(--secondary-color);
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: var(--accent-color);
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const SocialLink = styled.a`
  color: var(--primary-color);
  font-size: 24px;
  margin: 0 10px;
  transition: all 0.3s ease;

  &:hover {
    color: var(--accent-color);
    transform: translateY(-3px);
  }
`;

const Contact = () => {
  return (
    <>
      <ContactHeader>
        <div className="container">
          <ContactTitle>Get in Touch</ContactTitle>
          <ContactDescription>Have questions about our Computer Vision AI solutions? We're here to help!</ContactDescription>
        </div>
      </ContactHeader>

      <main className="container">
        <ContactContent>
          <ContactForm action="/submit-contact" method="POST">
            <FormTitle>Send Us a Message</FormTitle>
            <FormGroup>
              <FormLabel htmlFor="name">Full Name</FormLabel>
              <FormInput type="text" id="name" name="name" required />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="email">Email Address</FormLabel>
              <FormInput type="email" id="email" name="email" required />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="company">Company</FormLabel>
              <FormInput type="text" id="company" name="company" />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="subject">Subject</FormLabel>
              <FormSelect id="subject" name="subject" required>
                <option value="">Select a subject</option>
                <option value="general">General Inquiry</option>
                <option value="sales">Sales</option>
                <option value="support">Technical Support</option>
                <option value="partnership">Partnership Opportunities</option>
              </FormSelect>
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="message">Message</FormLabel>
              <FormTextarea id="message" name="message" required></FormTextarea>
            </FormGroup>
            <SubmitButton type="submit">Send Message</SubmitButton>
          </ContactForm>

          <ContactInfo>
            <InfoCard>
              <InfoCardTitle>Visit Us</InfoCardTitle>
              <InfoCardText>123 AI Boulevard<br />Silicon Valley, CA 94000<br />United States</InfoCardText>
            </InfoCard>
            <InfoCard>
              <InfoCardTitle>Call Us</InfoCardTitle>
              <InfoCardLink href="tel:+1234567890">+1 (234) 567-890</InfoCardLink>
            </InfoCard>
            <InfoCard>
              <InfoCardTitle>Email Us</InfoCardTitle>
              <InfoCardLink href="mailto:info@netrah.ai">info@netrah.ai</InfoCardLink>
            </InfoCard>
            <InfoCard>
              <InfoCardTitle>Follow Us</InfoCardTitle>
              <SocialLinks>
                <SocialLink href="https://twitter.com/netrah_ai" target="_blank"><i className="fab fa-twitter"></i></SocialLink>
                <SocialLink href="https://www.linkedin.com/company/netrah-ai" target="_blank"><i className="fab fa-linkedin"></i></SocialLink>
                <SocialLink href="https://github.com/netrah-ai" target="_blank"><i className="fab fa-github"></i></SocialLink>
              </SocialLinks>
            </InfoCard>
          </ContactInfo>
        </ContactContent>
      </main>
    </>
  );
};

export default Contact;
