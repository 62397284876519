import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --primary-color: #6C63FF;
    --secondary-color: #4A4754;
    --accent-color: #FF6B6B;
    --text-color: #333333;
    --bg-color: #F4F7FE;
    --card-bg: #FFFFFF;
    --footer-bg: #2C2B3C;
    --footer-text: #ffffff;
    --gradient: linear-gradient(135deg, #6C63FF, #FF6B6B);
  }

  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
    background-color: var(--bg-color);
    color: var(--text-color);
    line-height: 1.6;
  }
`;

const Hero = styled.section`
  background: var(--gradient);
  color: white;
  text-align: center;
  padding: 150px 0 100px;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);

  h1 {
    font-size: 52px;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  }

  p {
    font-size: 22px;
    max-width: 600px;
    margin: 0 auto 30px;
    opacity: 0.9;
  }
`;

const DemoSection = styled.main`
  padding: 100px 0;
`;

const DemoContainer = styled.div`
  background-color: var(--card-bg);
  border-radius: 30px;
  padding: 50px;
  box-shadow: 0 20px 40px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 30px 60px rgba(0,0,0,0.15);
  }
`;

const DemoControls = styled.div`
  margin-bottom: 40px;

  h2 {
    color: var(--primary-color);
    margin-bottom: 30px;
    font-size: 36px;
    text-align: center;
  }
`;

const ControlGroup = styled.div`
  margin-bottom: 40px;

  label {
    display: block;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 20px;
    color: var(--secondary-color);
  }

  input[type="file"] {
    width: 100%;
    padding: 20px;
    border: 3px dashed var(--primary-color);
    border-radius: 20px;
    font-size: 18px;
    transition: all 0.3s ease;
    cursor: pointer;
    background-color: rgba(108, 99, 255, 0.05);

    &:hover {
      background-color: rgba(108, 99, 255, 0.1);
      border-color: var(--accent-color);
    }
  }
`;

const RadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 15px 25px;
    background-color: #f0f0f0;
    border-radius: 50px;
    transition: all 0.3s ease;
    font-weight: 600;
    font-size: 18px;
    min-width: 200px;

    &:hover {
      background-color: #e0e0e0;
      transform: translateY(-3px);
      box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    }
  }

  input[type="radio"] {
    display: none;

    &:checked + label {
      background-color: var(--primary-color);
      color: white;
      box-shadow: 0 5px 15px rgba(108, 99, 255, 0.3);
    }
  }
`;

const DemoButton = styled.button`
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 18px 36px;
  font-size: 20px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 10px 20px rgba(108, 99, 255, 0.3);
  display: block;
  margin: 40px auto 0;
  font-weight: 700;

  &:hover {
    background-color: var(--accent-color);
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(255, 107, 107, 0.4);
  }
`;

const ResultSection = styled.div`
  margin-top: 60px;
  opacity: ${props => props.show ? 1 : 0};
  transform: translateY(${props => props.show ? 0 : '20px'});
  transition: all 0.5s ease;

  h3 {
    color: var(--primary-color);
    margin-bottom: 30px;
    font-size: 28px;
    text-align: center;
  }
`;

const ResultImage = styled.img`
  max-width: 100%;
  border-radius: 20px;
  box-shadow: 0 15px 30px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  animation: float 6s ease-in-out infinite;

  &:hover {
    transform: scale(1.03);
    box-shadow: 0 20px 40px rgba(0,0,0,0.15);
  }

  @keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-20px); }
    100% { transform: translateY(0px); }
  }
`;

const Demo = () => {
  const [showResult, setShowResult] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowResult(true);
  };

  return (
    <>
      <GlobalStyle />
      <Hero>
        <div className="container">
          <h1>Revolutionize Your Images with AI</h1>
          <p>Harness the power of cutting-edge AI to transform your visuals like never before</p>
        </div>
      </Hero>

      <DemoSection>
        <div className="container">
          <DemoContainer>
            <DemoControls>
              <h2>Unleash Your Creative Vision</h2>
              <form onSubmit={handleSubmit}>
                <ControlGroup>
                  <label htmlFor="image-upload">Drag & Drop Your Masterpiece:</label>
                  <input type="file" id="image-upload" name="image" accept="image/*" required />
                </ControlGroup>
                <ControlGroup>
                  <label>Select Your Transformation:</label>
                  <RadioGroup>
                    <input type="radio" id="background-removal" name="mode" value="background-removal" required />
                    <label htmlFor="background-removal">Background Vanish</label>
                    
                    <input type="radio" id="image-enhancement" name="mode" value="image-enhancement" required />
                    <label htmlFor="image-enhancement">Super Enhance</label>
                    
                    <input type="radio" id="image-warping" name="mode" value="image-warping" required />
                    <label htmlFor="image-warping">Reality Bend</label>
                    
                    <input type="radio" id="image-to-base64" name="mode" value="image-to-base64" required />
                    <label htmlFor="image-to-base64">Digital Alchemy</label>
                  </RadioGroup>
                </ControlGroup>
                <DemoButton type="submit">Transform Now</DemoButton>
              </form>
            </DemoControls>
            
            <ResultSection show={showResult}>
              <h3>Behold Your Transformed Masterpiece</h3>
              <ResultImage id="result-image" alt="Transformed image result" />
              <div id="base64-result" style={{ display: 'none' }}>
                <h4>Digital Alchemy Result:</h4>
                <textarea id="base64-output" rows="5" readOnly style={{ width: '100%', marginTop: '20px', padding: '15px', borderRadius: '10px', border: '1px solid #ddd', fontFamily: 'monospace', fontSize: '14px', resize: 'vertical' }}></textarea>
              </div>
            </ResultSection>
          </DemoContainer>
        </div>
      </DemoSection>
    </>
  );
};

export default Demo;
