import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const HeroSection = styled.section`
  background: var(--gradient);
  padding: 150px 0 100px;
  text-align: center;
  color: white;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
`;

const HeroTitle = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
`;

const HeroDescription = styled.p`
  font-size: 20px;
  max-width: 800px;
  margin: 0 auto 40px;
  opacity: 0.9;
`;

const PricingGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 80px 0;
`;

const PricingCard = styled.div`
  background-color: var(--card-bg);
  border-radius: 20px;
  padding: 40px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(108, 99, 255, 0.2);
  }
`;

const PlanTitle = styled.h2`
  font-size: 28px;
  color: var(--primary-color);
  margin-bottom: 20px;
`;

const Price = styled.div`
  font-size: 48px;
  font-weight: bold;
  color: var(--accent-color);
  margin-bottom: 20px;

  span {
    font-size: 18px;
    color: var(--secondary-color);
  }
`;

const FeaturesList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;

  li {
    margin-bottom: 10px;
    color: var(--secondary-color);
  }
`;

const CTAButton = styled(Link)`
  display: inline-block;
  background-color: var(--primary-color);
  color: white;
  padding: 12px 30px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 25px rgba(0,0,0,0.2);
    background-color: var(--accent-color);
  }
`;

const EnterpriseSection = styled.section`
  background-color: var(--secondary-color);
  color: white;
  text-align: center;
  padding: 80px 0;
  clip-path: polygon(0 15%, 100% 0, 100% 85%, 0 100%);
`;

const EnterpriseTitle = styled.h2`
  font-size: 36px;
  margin-bottom: 20px;
`;

const EnterpriseDescription = styled.p`
  font-size: 18px;
  max-width: 800px;
  margin: 0 auto 40px;
  opacity: 0.9;
`;

const Pricing = () => {
  return (
    <>
      <HeroSection>
        <div className="container">
          <HeroTitle>Choose Your Plan</HeroTitle>
          <HeroDescription>Flexible pricing options to suit your Computer Vision AI needs</HeroDescription>
        </div>
      </HeroSection>

      <main className="container">
        <PricingGrid>
          <PricingCard>
            <div>
              <PlanTitle>Starter</PlanTitle>
              <Price>$99<span>/month</span></Price>
              <FeaturesList>
                <li>Up to 10,000 API calls/month</li>
                <li>Basic object detection</li>
                <li>Image classification</li>
                <li>24/7 email support</li>
                <li>Access to documentation</li>
              </FeaturesList>
            </div>
            <CTAButton to="/signup?plan=starter">Get Started</CTAButton>
          </PricingCard>
          <PricingCard>
            <div>
              <PlanTitle>Professional</PlanTitle>
              <Price>$299<span>/month</span></Price>
              <FeaturesList>
                <li>Up to 50,000 API calls/month</li>
                <li>Advanced object detection</li>
                <li>Facial recognition</li>
                <li>Real-time video analysis</li>
                <li>24/7 priority support</li>
                <li>API integration assistance</li>
              </FeaturesList>
            </div>
            <CTAButton to="/signup?plan=professional">Upgrade Now</CTAButton>
          </PricingCard>
          <PricingCard>
            <div>
              <PlanTitle>Business</PlanTitle>
              <Price>$799<span>/month</span></Price>
              <FeaturesList>
                <li>Up to 200,000 API calls/month</li>
                <li>Full suite of CV AI features</li>
                <li>Custom model training</li>
                <li>Dedicated account manager</li>
                <li>24/7 phone & email support</li>
                <li>Onboarding & training sessions</li>
              </FeaturesList>
            </div>
            <CTAButton to="/signup?plan=business">Contact Sales</CTAButton>
          </PricingCard>
        </PricingGrid>
      </main>

      <EnterpriseSection>
        <div className="container">
          <EnterpriseTitle>Enterprise Solutions</EnterpriseTitle>
          <EnterpriseDescription>
            Need a custom solution? Our enterprise plan offers tailored features, unlimited API calls, and dedicated support to meet your organization's unique needs.
          </EnterpriseDescription>
          <CTAButton to="/contact?enterprise">Get in Touch</CTAButton>
        </div>
      </EnterpriseSection>
    </>
  );
};

export default Pricing;
