import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faVideo, faChartSimple } from '@fortawesome/free-solid-svg-icons';



const Hero = styled.section`
  background: var(--gradient);
  padding: 180px 0 120px;
  text-align: center;
  color: white;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  margin-bottom: 80px;
`;

const HeroTitle = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
`;

const HeroDescription = styled.p`
  font-size: 20px;
  max-width: 800px;
  margin: 0 auto 40px;
  opacity: 0.9;
`;

const CTAButton = styled(Link)`
  display: inline-block;
  background-color: white;
  color: var(--primary-color);
  padding: 15px 30px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 25px rgba(0,0,0,0.2);
    background-color: var(--accent-color);
    color: white;
  }
`;

const Features = styled.section`
  padding: 100px 0;
`;

const FeaturesTitle = styled.h2`
  text-align: center;
  font-size: 36px;
  margin-bottom: 50px;
  color: var(--primary-color);
`;

const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
`;

const FeatureCard = styled.div`
  background-color: var(--card-bg);
  border-radius: 20px;
  padding: 30px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(108, 99, 255, 0.2);
  }
`;

const FeatureIcon = styled(FontAwesomeIcon)`
  font-size: 48px;
  color: var(--accent-color);
  margin-bottom: 20px;
  animation: float 3s ease-in-out infinite;

  @keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
  }
`;

const FeatureTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 15px;
  color: var(--primary-color);
`;

const DemoSection = styled.section`
  background-color: var(--secondary-color);
  color: white;
  padding: 100px 0;
  text-align: center;
  clip-path: polygon(0 10%, 100% 0, 100% 90%, 0 100%);
`;

const DemoTitle = styled.h2`
  font-size: 36px;
  margin-bottom: 30px;
`;

const DemoVideo = styled.div`
  max-width: 800px;
  margin: 0 auto;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 50px rgba(0,0,0,0.3);
`;

const Testimonials = styled.section`
  padding: 100px 0;
  background-color: #f9f9f9;
`;

const TestimonialSlider = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const Testimonial = styled.div`
  text-align: center;
  padding: 30px;
`;

const TestimonialImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
`;

const TestimonialText = styled.p`
  font-style: italic;
  margin-bottom: 20px;
`;

const TestimonialAuthor = styled.span`
  font-weight: bold;
  color: var(--primary-color);
`;

const Home = () => {
  return (
    <>
      <Hero>
        <div className="container">
          <HeroTitle>Revolutionary Computer Vision AI</HeroTitle>
          <HeroDescription>Unlock the power of visual intelligence with Netrah's cutting-edge AI technology</HeroDescription>
          <CTAButton to="/demo">Experience the Demo</CTAButton>
        </div>
      </Hero>

      <Features>
        <div className="container">
          <FeaturesTitle>Unleash the Power of AI Vision</FeaturesTitle>
          <FeaturesGrid>
          <FeatureCard>
                <FeatureIcon icon={faEye} />
                <FeatureTitle>Advanced Object Recognition</FeatureTitle>
                <p>Identify and classify objects with superhuman accuracy in real-time.</p>
            </FeatureCard>
            <FeatureCard>
                <FeatureIcon icon={faVideo} />
                <FeatureTitle>Real-time Video Analysis</FeatureTitle>
                <p>Process and analyze live video streams for instant insights and decision-making.</p>
            </FeatureCard>
            <FeatureCard>
                <FeatureIcon icon={faChartSimple} />
                <FeatureTitle>Visual Data Analytics</FeatureTitle>
                <p>Extract meaningful insights from complex visual data for business intelligence.</p>
            </FeatureCard>
          </FeaturesGrid>
        </div>
      </Features>

      <DemoSection>
  <div className="container">
    <DemoTitle>See Netrah in Action</DemoTitle>
    <DemoVideo>
      <iframe 
        width="100%" 
        height="450" 
        src="https://www.youtube.com/embed/dQw4w9WgXcQ" 
        title="Netrah Demo Video" 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen>
      </iframe>
    </DemoVideo>
  </div>
</DemoSection>

      <Testimonials>
        <div className="container">
          <h2>What Our Clients Say</h2>
          <TestimonialSlider>
            <Testimonial>
              <TestimonialImage src="/testimonial-1.jpg" alt="Sarah Johnson" />
              <TestimonialText>"Netrah's AI has revolutionized our quality control process. We've seen a 40% increase in efficiency since implementation."</TestimonialText>
              <TestimonialAuthor>Sarah Johnson, QC Manager at TechCorp</TestimonialAuthor>
            </Testimonial>
          </TestimonialSlider>
        </div>
      </Testimonials>
    </>
  );
};

export default Home;
