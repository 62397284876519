import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin, faGithub, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: var(--footer-bg);
  color: var(--footer-text);
  padding: 120px 0 60px;
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 100%);
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const FooterSection = styled.div`
  flex: 1;
  min-width: 200px;
  margin-bottom: 20px;
  padding: 0 10px;
`;

const FooterTitle = styled.h3`
  color: var(--accent-color);
  margin-bottom: 15px;
  font-size: 18px;
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterLink = styled(Link)`
  color: var(--footer-text);
  text-decoration: none;
  margin-bottom: 8px;
  transition: color 0.3s ease;

  &:hover {
    color: var(--accent-color);
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const SocialLink = styled.a`
  color: var(--footer-text);
  font-size: 24px;
  margin: 0 15px;
  transition: all 0.3s ease;

  &:hover {
    color: var(--accent-color);
    transform: translateY(-3px);
  }
`;

const FooterBottom = styled.div`
  text-align: center;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.8rem;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContent>
        <FooterSection>
          <FooterTitle>Quick Links</FooterTitle>
          <FooterLinks>
            <FooterLink to="/about">About Us</FooterLink>
            <FooterLink to="/features">Features</FooterLink>
            <FooterLink to="/pricing">Pricing</FooterLink>
            <FooterLink to="/blog">Blog</FooterLink>
            <FooterLink to="/contact">Contact</FooterLink>
          </FooterLinks>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Resources</FooterTitle>
          <FooterLinks>
            <FooterLink to="/documentation">Documentation</FooterLink>
            <FooterLink to="/api">API</FooterLink>
            <FooterLink to="/tutorials">Tutorials</FooterLink>
            <FooterLink to="/case-studies">Case Studies</FooterLink>
          </FooterLinks>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Legal</FooterTitle>
          <FooterLinks>
            <FooterLink to="/terms">Terms of Service</FooterLink>
            <FooterLink to="/privacy">Privacy Policy</FooterLink>
            <FooterLink to="/cookies">Cookie Policy</FooterLink>
            <FooterLink to="/gdpr">GDPR Compliance</FooterLink>
          </FooterLinks>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Company</FooterTitle>
          <FooterLinks>
            <FooterLink to="/careers">Careers</FooterLink>
            <FooterLink to="/press">Press</FooterLink>
            <FooterLink to="/partners">Partners</FooterLink>
            <FooterLink to="/investors">Investors</FooterLink>
          </FooterLinks>
        </FooterSection>
      </FooterContent>
      <SocialLinks>
        <SocialLink href="https://twitter.com/netrah_ai" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} />
        </SocialLink>
        <SocialLink href="https://www.linkedin.com/company/netrah-ai" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} />
        </SocialLink>
        <SocialLink href="https://github.com/netrah-ai" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithub} />
        </SocialLink>
        <SocialLink href="https://www.youtube.com/netrah_ai" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faYoutube} />
        </SocialLink>
        <SocialLink href="https://www.instagram.com/netrah_ai" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} />
        </SocialLink>
      </SocialLinks>
      <FooterBottom>
        <p>&copy; 2023 Netrah. All rights reserved. Pioneering the future of computer vision with cutting-edge AI technology.</p>
      </FooterBottom>
    </FooterWrapper>
  );
};

export default Footer;
