import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const BlogHeader = styled.section`
  background: var(--gradient);
  color: white;
  text-align: center;
  padding: 150px 0 100px;
  margin-bottom: 80px;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
`;

const BlogTitle = styled.h1`
  font-size: 48px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
`;

const BlogDescription = styled.p`
  font-size: 20px;
  max-width: 600px;
  margin: 0 auto;
  opacity: 0.9;
`;

const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 40px;
  margin-bottom: 80px;
`;

const BlogCard = styled.article`
  background-color: var(--card-bg);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;

  &:hover {
    transform: translateY(-10px) scale(1.02);
    box-shadow: 0 15px 40px rgba(108, 99, 255, 0.2);
  }
`;

const BlogImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: transform 0.3s ease;

  ${BlogCard}:hover & {
    transform: scale(1.05);
  }
`;

const BlogContent = styled.div`
  padding: 30px;
  position: relative;
`;

const BlogCategory = styled.span`
  position: absolute;
  top: -15px;
  left: 20px;
  background: var(--gradient);
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
`;

const BlogCardTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 15px;
  color: var(--primary-color);
  transition: color 0.3s ease;
`;

const BlogCardDescription = styled.p`
  font-size: 16px;
  color: var(--secondary-color);
  margin-bottom: 20px;
`;

const ReadMoreLink = styled(Link)`
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--accent-color);
  color: white;
  text-decoration: none;
  border-radius: 30px;
  transition: all 0.3s ease;
  font-weight: 600;

  &:hover {
    background-color: var(--primary-color);
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(108, 99, 255, 0.3);
  }
`;

const Blog = () => {
  return (
    <>
      <BlogHeader>
        <div className="container">
          <BlogTitle>Netrah Insights</BlogTitle>
          <BlogDescription>Dive into the future of Computer Vision AI with our cutting-edge articles and breakthroughs</BlogDescription>
        </div>
      </BlogHeader>

      <main className="container">
      <BlogGrid>
        <BlogCard>
            <BlogImage src="/blog/ai-ethics.jpg" alt="AI Ethics in Computer Vision" />
            <BlogContent>
            <BlogCategory>Ethics</BlogCategory>
            <BlogCardTitle>AI Ethics in Computer Vision: Navigating the Future</BlogCardTitle>
            <BlogCardDescription>Exploring the ethical considerations and challenges in developing responsible AI vision systems.</BlogCardDescription>
            <ReadMoreLink to="/blog/ai-ethics-in-computer-vision">Explore Insights</ReadMoreLink>
            </BlogContent>
        </BlogCard>
        
        <BlogCard>
            <BlogImage src="/blog/real-time-object-detection.jpg" alt="Real-time Object Detection" />
            <BlogContent>
            <BlogCategory>Technology</BlogCategory>
            <BlogCardTitle>Advancements in Real-time Object Detection</BlogCardTitle>
            <BlogCardDescription>A deep dive into the latest algorithms and techniques powering lightning-fast object detection.</BlogCardDescription>
            <ReadMoreLink to="/blog/advancements-in-real-time-object-detection">Uncover Breakthroughs</ReadMoreLink>
            </BlogContent>
        </BlogCard>
        
        <BlogCard>
            <BlogImage src="/blog/ai-healthcare.jpg" alt="AI in Healthcare" />
            <BlogContent>
            <BlogCategory>Healthcare</BlogCategory>
            <BlogCardTitle>Revolutionizing Healthcare with Computer Vision AI</BlogCardTitle>
            <BlogCardDescription>How AI-powered image analysis is transforming diagnostics and patient care in the medical field.</BlogCardDescription>
            <ReadMoreLink to="/blog/ai-revolutionizing-healthcare">Discover Impact</ReadMoreLink>
            </BlogContent>
        </BlogCard>
        
        <BlogCard>
            <BlogImage src="/blog/edge-computing.jpg" alt="Edge Computing in AI" />
            <BlogContent>
            <BlogCategory>Innovation</BlogCategory>
            <BlogCardTitle>Edge Computing: Bringing AI Vision to the Field</BlogCardTitle>
            <BlogCardDescription>Exploring the potential of edge devices in deploying computer vision applications in remote locations.</BlogCardDescription>
            <ReadMoreLink to="/blog/edge-computing-in-ai-vision">Explore Frontiers</ReadMoreLink>
            </BlogContent>
        </BlogCard>
        
        <BlogCard>
            <BlogImage src="/blog/ai-retail.jpg" alt="AI in Retail" />
            <BlogContent>
            <BlogCategory>Retail</BlogCategory>
            <BlogCardTitle>Transforming Retail with Intelligent Vision Systems</BlogCardTitle>
            <BlogCardDescription>How computer vision is reshaping the shopping experience and optimizing retail operations.</BlogCardDescription>
            <ReadMoreLink to="/blog/ai-transforming-retail">See the Future</ReadMoreLink>
            </BlogContent>
        </BlogCard>
        
        <BlogCard>
            <BlogImage src="/blog/3d-reconstruction.jpg" alt="3D Reconstruction" />
            <BlogContent>
            <BlogCategory>3D Vision</BlogCategory>
            <BlogCardTitle>The Future of 3D Reconstruction in Computer Vision</BlogCardTitle>
            <BlogCardDescription>Unveiling the latest techniques in creating detailed 3D models from 2D images and their applications.</BlogCardDescription>
            <ReadMoreLink to="/blog/future-of-3d-reconstruction">Explore Dimensions</ReadMoreLink>
            </BlogContent>
        </BlogCard>
        </BlogGrid>
      </main>
    </>
  );
};

export default Blog;
