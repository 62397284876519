import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => (
  <header>
    <div className="container">
      <nav>
        <Link to="/" className="logo">
          <img src={process.env.PUBLIC_URL + '/logo7.png'} alt="Netrah Logo" width="80" height="80" />
          Netrah
        </Link>
        <div className="nav-links">
          <Link to="/">Home</Link>
          <Link to="/features">Features</Link>
          <Link to="/pricing">Pricing</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/contact">Contact</Link>
        </div>
      </nav>
    </div>
  </header>
);

export default Header;
